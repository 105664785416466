body {
  padding: 0;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.aside {
  display: block;
  background-color: #f9f9f9;
  border-right: 1px solid #dedede;
}
.messages {
  display: block;
  background-color: #fff;
  border-right: 1px solid #dedede;
  padding: 40px 20px;
}
.message {
  display: block;
  background-color: #fff;
  padding: 40px 20px;
}
.aside .compose {
  height: 95px;
  margin: 0 -10px;
  padding: 25px 30px;
}
.aside .compose .button {
  color: #f6f7f7;
}
.aside .compose .button .compose {
  font-size: 14px;
  font-weight: 700;
}
.aside .main {
  padding: 40px;
  color: #6f7b7e;
}
.aside .title {
  color: #6f7b7e;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.aside .main .item {
  display: block;
  padding: 10px 0;
  color: #6f7b7e;
}
.aside .main .item.active {
  background-color: #f1f1f1;
  margin: 0 -50px;
  padding-left: 50px;
}
.aside .main .item:active,
.aside .main .item:hover {
  background-color: #f2f2f2;
  margin: 0 -50px;
  padding-left: 50px;
}
.aside .main .icon {
  font-size: 19px;
  padding-right: 30px;
  color: #a0a0a0;
}
.aside .main .name {
  font-size: 15px;
  color: #5d5d5d;
  font-weight: 500;
}
.messages .action-buttons {
  padding: 0;
  margin-top: -20px;
}
.message .action-buttons {
  padding: 0;
  margin-top: -5px;
}
.action-buttons .control.is-grouped {
  display: inline-block;
  margin-right: 30px;
}
.action-buttons .control.is-grouped:last-child {
  margin-right: 0;
}
.action-buttons .control.is-grouped .button:first-child {
  border-radius: 5px 0 0 5px;
}
.action-buttons .control.is-grouped .button:last-child {
  border-radius: 0 5px 5px 0;
}
.action-buttons .control.is-grouped .button {
  margin-right: -5px;
  border-radius: 0;
}
.pg {
  display: inline-block;
  top: 10px;
}
.action-buttons .pg .title {
  display: block;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 3px;
  font-size: 12px;
  color: #aaaaaa;
}
.action-buttons .pg a {
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: none;
}
.is-grouped .button {
  background-image: linear-gradient(#f8f8f8, #f1f1f1);
}
.is-grouped .button .fa {
  font-size: 15px;
  color: #aaaaaa;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #dbdbdb;
  right: 1.125em;
  z-index: 4;
}
.select:not(.is-multiple):not(.is-loading):hover::after {
  border-color: #dbdbdb;
}
.message-preview {
  margin-top: 60px;
}
.inbox-messages .card {
  width: 100%;
  margin-bottom: 10px;
}

.inbox-messages .msg-check {
  padding: 0 20px;
}
.inbox-messages .msg-subject {
  padding: 10px 0;
  color: #5d5d5d;
}
.inbox-messages .msg-attachment {
  float: right;
}

.inbox-messages .msg-subject .fa {
  font-size: 14px;
  padding: 3px 0;
}
.inbox-messages .msg-timestamp {
  float: right;
  padding: 0 20px;
  color: #5d5d5d;
  padding-right: 0px;
}
.message-preview .avatar {
  display: inline-block;
}
.message-preview .top .address {
  display: inline-block;
  padding: 0 20px;
}
.avatar img {
  width: 40px;
  border-radius: 50px;
  border: 2px solid #999;
  padding: 2px;
}
.address .name {
  font-size: 16px;
  font-weight: bold;
}
.address .email {
  font-weight: bold;
  color: #b6c7d1;
}
.card.active {
  background-color: #f5f5f5;
}

.aside .main {
  padding: 34px;
  color: #6f7b7e;
}
.message a:not(.button):not(.tag):not(.dropdown-item) {
  text-decoration: none;
}
.timeline .timeline-item .timeline-content {
  padding: 1em 0 0 2em;
  width: 100%;
}
.timeline .timeline-item .timeline-marker.is-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 0px;
}
.timeline .timeline-item {
  display: flex;
  display: -webkit-flex;
  position: relative;
  margin-left: 2em;
  padding-bottom: 0em;
}
.footer {
  background-color: #ffffff;
  padding: 2rem 1.5rem 2rem;
  border-radius: 7px;
}

/* side nav css */
.logo {
  display: flex;
  vertical-align: middle;
  text-align: center;
}

.botton-nav {
  position: fixed;
  bottom: 0;
  padding: 29px;
}
.card-content {
  background-color: transparent;
  padding: 0.5rem;
}
.messages {
  display: block;
  background-color: #8964f3;
  /* background-image: url("../assets/pink_bg.png"); */
  background-position: top;
  background-image: linear-gradient(19deg, #cc93ef 0%, #8964f3 100%);
  border-right: 1px solid #dedede;
  padding: 38px 20px 0px;
}
.pageloader {
  background-color: #8964f3 !important;
  background-position: top;
  background-image: linear-gradient(19deg, #cc93ef 0%, #8964f3 100%) !important;
}
.messages-title {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}
.messages-subtitle {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 1.5rem;
}
.messages-goal-text {
  color: white;
}
.card-padding {
  padding-left: 15px;
  padding-right: 15px;
}
.card-border {
  border-radius: 7px;
}
.aside .main .item.active {
  background-color: #986ef2;
  margin: 0 -50px;
  padding-left: 50px;
}
.scroll {
  height: 70vh;
  overflow-x: auto;
  padding-right: 2px;
}
.scroll::-webkit-scrollbar {
  width: 2px;
}
.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
}
.scroll::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}
.scroll::-webkit-scrollbar-thumb:hover {
  background: #dbdbdb;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #986ef2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #986ef2;
}
.is-checkradio[type="radio"] + label::after,
.is-checkradio[type="radio"] + label:after {
  border-radius: 50%;
  background: #8964f3;
  left: 0;
  transform: scale(0.5);
}
.is-checkradio[type="radio"]:hover:not([disabled]) + label::before,
.is-checkradio[type="radio"]:hover:not([disabled]) + label:before,
.is-checkradio[type="checkbox"]:hover:not([disabled]) + label::before,
.is-checkradio[type="checkbox"]:hover:not([disabled]) + label:before {
  border-color: #8964f3 !important;
}
.field-confirm {
  padding-top: 20px;
  padding-bottom: 20px;
}
/* .hero {
  height: -webkit-fill-available;
} */
svg {
  height: 40vh;
  margin: auto;
  display: block;
}

path {
  stroke-linecap: round;
  stroke-width: 10;
}

path.grey {
  stroke: #fff;
}

path.purple {
  stroke: url(#header-shape-gradient) rgb(0, 0, 0);
  /* stroke-dasharray: calc(40 * 3.142 * 1.85); */
  stroke-width: 6;
}

.custom-title {
  font-size: 50px !important;
  font-weight: 700 !important;
  color: #fff !important;
}

.remaining-text {
  font-size: 8px;
  font-weight: 300;
  fill: #fff;
}

.time-display {
  font-size: 18px;
  font-weight: 600;
  fill: #fff;
}

.button.is-white {
  background-color: white;
  border-color: transparent;
  color: #0a0a0a;
  padding-left: 12px;
  padding-right: 12px;
  margin: 5px;
}
#header-shape-gradient {
  --color-stop: #8964f3;
  --color-bot: #cc93ef;
}
.hero-custom-bg {
  background-color: #8964f3;
  background-image: linear-gradient(19deg, #cc93ef 0%, #8964f3 100%);
  /* background-image: url("../assets/pink_bg.png"); */
}
.session-subtitle {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 1.5rem;
}
.small-subtitle {
  color: white;
}
.cross-out {
  text-decoration: line-through;
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + div {
  height: 90px;
  width: 150px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 9px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

input[type="radio"] + div:hover {
  border-color: #c2c2c2;
}
input[type="radio"]:checked + div {
  background-image: linear-gradient(19deg, #cc93ef 0%, #8964f3 100%);
  color: white;
}
.radio-container {
  padding: 20px;
  text-align: center;
}
.label-control {
  padding: 2px;
}
.finish-container {
  text-align: center;
  padding: 50px;
}
.hero-body-custom {
  margin: auto;
}
.logo-padding {
  padding: 1px 0px 30px 0px;
}
.hero-body-session-custom {
  padding: 10px;
  margin: auto;
}
.button-padding {
  padding-top: 20px;
}
.is-7 {
  flex: auto !important;
}
.start-hero-main {
  height: 100vh;
  display: flex;
  vertical-align: middle;
  text-align: center;
}
.sidebar {
  width: 50px;
  height: 100%;
  background: #fff;
}

.sidebar ul li {
  background-color: whitesmoke;
  text-align: center;
  padding: 15px;
  border-radius: 0px;
  margin: 0px;
}

.sidebar .active {
  background-color: #956cf2;
  color: white;
  padding: 15px;
  border-radius: 0px;
  margin: 0px;
}

.sidebar h2 {
  text-align: center;
}

.sidebar h2 img {
  width: 54px;
  margin-top: 7px;
}

.sidebar ul li:hover {
  background-color: #956cf2;
  color: white;
  padding: 15px;
  border-radius: 0px;
  margin: 0px;
}
.columns {
  margin: 0 !important;
}
.is-icon-time {
  top: 42% !important;
}
.column-goal {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 !important;
}
.tag-text-min {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: -8px;
}
.tag-text-min-small {
  font-size: 8px;
  font-weight: 200;
  text-transform: uppercase;
}
.tag:not(body) {
  align-items: center;
  background-color: white;
  border-radius: 4px;
  color: #000000;
  display: inline-flex;
  font-size: 0.75rem;
  height: 35px;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}
.inbox-messages .msg-subject {
  padding: 10px 0;
  color: #000000;
}
.sidebar-text {
  font-size: 7px;
  font-weight: 700;
  text-transform: uppercase;
}
.icon-logo {
  font-size: 34px;
  color: #8f69f2;
}
.is-button-custom {
  background-color: #a678f2;
  background-image: linear-gradient(19deg, #cc93ef 0%, #8964f3 100%);
  color: white;
  border-radius: 10px;
  border-color: #b684f1;
}
.is-button-custom.is-hovered,
.is-button-custom:hover {
  border-color: #a678f2;
  color: #ffffff;
  border-color: #b684f1;
}
.is-button-custom.is-focused,
.is-button-custom:focus {
  border-color: #a678f2;
  color: #ffffff;
}
.inbox-messages .card {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 12px;
}
.content-card-p {
  padding-left: 8px;
  padding-bottom: 5px;
}
.card-header {
  background-color: transparent;
  align-items: stretch;
  box-shadow: 0 0.125em 0.25em rgb(10 10 10 / 0%);
  display: flex;
}
.inbox-messages {
  border-radius: 14px;
}
svg {
  height: auto;
  margin: auto;
  display: block;
}
/* .button.is-white.is-outlined {
  border-radius: 10px;
} */
.custom-input {
  background-color: #fff0;
  border-color: #ffffff;
  border-radius: 4px;
  color: #ffffff;
}
.custom-input:active,
.custom-input:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0 0.125em rgb(72 95 199 / 0%);
}
.has-text-success {
  color: #c68eef !important;
}
.input:active,
.input:focus,
.is-active.input,
.is-active.textarea,
.is-focused.input,
.is-focused.textarea,
.select select.is-active,
.select select.is-focused,
.select select:active,
.select select:focus,
.textarea:active,
.textarea:focus {
  border-color: #ad7ef1;
  box-shadow: 0 0 0 0.125em rgb(226 198 247);
}
.button.is-white.is-outlined {
  border-radius: 10px;
}
.moving-outline {
  height: 30vh;
  margin: auto;
}
